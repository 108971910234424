import React from 'react';
import { graphql, useStaticQuery, withPrefix } from 'gatsby';

const CardAuthor = () => {
  const currentYear = new Date().getFullYear();
  const exp = currentYear - 2016;
  const data = useStaticQuery(graphql`
    query SocialQuery {
      allSocialJson {
        edges {
          node {
            name
            image
            link
          }
        }
      }
    }
  `);

  return (
    <div className='strip'>
      <div className='container pt-0 pb-5 pl-0 pr-0'>
        <div className='author-card'>
          <div>
            <img
              src={withPrefix('/images/avatar.png')}
              loading='lazy'
              width='128'
              alt='Avatar'
            />
          </div>
          <div className='author-card-column'>
            <div>
              <h3 className='author-card-h5 mb-0'>Yasin Junet</h3>
              <p className='author-card-p fw-semi-bold'>
                Frontend engineer / freelancer
              </p>
              <p className='author-card-p mb-0'>
                I'm {exp}+ years experienced Indonesian interactive front-end
                developer with a passion for designing detailed and user-focused
                web apps. I will help take your online presence to the next
                level.
              </p>
            </div>
            <div className='author-card-socials'>
              {data.allSocialJson.edges.map(({ node }) => (
                <a key={node.name} href={node.link} target='blank'>
                  <img src={node.image} title={node.name} alt={node.name} />
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardAuthor;

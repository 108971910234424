import React from 'react';
import { graphql, withPrefix, Link } from 'gatsby';

import CardAuthor from '../components/CardAuthor';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

const About = ({ data }) => {
  const currentYear = new Date().getFullYear();
  const exp = currentYear - 2016;

  return (
    <Layout>
      <div className='strip strip-blog strip-blog-page'>
        <div className='container'>
          <div className='row justify-content-center align-items-center'>
            <div className='col-12 col-md-10 col-lg-8'>
              <span className='section-title mb-3'>About Me</span>
              <div className='content'>
                <p>Welcome to my corner of the digital world! 👋</p>
                <blockquote>
                  <p>
                    "I'm <strong>Yasin Junet</strong>, a frontend engineer and
                    freelancer with over {exp} of experience, based in
                    Indonesia. I have dedicated myself to helping clients
                    elevate their online presence to the next level."
                  </p>
                </blockquote>
                <p>
                  Step into a world of captivating designs, seamless interfaces,
                  and transformative digital experiences - explore{' '}
                  <Link to='/projects'>my portfolios</Link> or{' '}
                  <Link to='/blog'>blogs</Link>, and witness the fusion of
                  creativity and expertise that awaits you.
                </p>
                <p>
                  As a <strong>passionate traveler</strong>, I find immense joy
                  in exploring the stunning landscapes of Indonesia. From its
                  breathtaking beaches to its majestic mountains, this diverse
                  country has captured my heart.
                </p>
                <p>
                  <img
                    src={withPrefix('/images/padar.jpg')}
                    loading='lazy'
                    width='100%'
                    alt='A day in Padar island, Indonesia'
                  />
                  <div className='img-caption'>
                    A day in Padar island, Indonesia
                  </div>
                </p>
                <p>
                  Along my journeys, I have developed a deep love for{' '}
                  <strong>landscape photography</strong>, capturing the essence
                  of each location through my lens.
                </p>
                <p>
                  My services encompass design, development, and optimization,
                  allowing me to create remarkable digital experiences, as you
                  can see on my{' '}
                  <a href='https://www.upwork.com/freelancers/~01670327b31b02c76e'>
                    Upwork
                  </a>{' '}
                  profile, or{' '}
                  <a href='https://id.linkedin.com/in/yasinjunet'>LinkedIn</a>.
                  Whether you're an entrepreneur seeking to establish a
                  compelling online presence or a business looking to enhance
                  your brand, I am here to guide you.
                </p>
                <p>
                  Together, we'll craft captivating designs, develop seamless
                  interfaces, and optimize your digital presence for maximum
                  impact. Join me on this exciting journey as we unlock the full
                  potential of your online presence. Together, we'll shape a
                  digital realm that captivates audiences and leaves a lasting
                  impression.
                </p>
                <p>Let's shape the future together, one pixel at a time :)</p>
              </div>
              <CardAuthor />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allSocialJson {
      edges {
        node {
          name
          image
          link
        }
      }
    }
  }
`;

export const Head = () => <SEO title='About' />;

export default About;
